// TEMA: Added a checkbox that is displayed based on a single_choice selection

import _ from 'lodash';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {Field} from 'react-final-form';
import {useSelector} from 'react-redux';
import {Label} from 'semantic-ui-react';

import {
  FinalCheckbox,
  FinalDropdown,
  FinalInput,
  parsers as p,
  validators as v,
} from 'indico/react/forms';
import {Translate} from 'indico/react/i18n';

import {getCurrency, getItems} from '../../form/selectors';
import {getFieldValue} from '../../form_submission/selectors';

import MultiChoiceInput from './MultiChoiceInput';
import {PlacesLeft} from './PlacesLeftLabel';

import styles from '../../../styles/regform.module.scss';

export default function ConditionalCheckboxInput({
  id,
  htmlName,
  disabled,
  title,
  isRequired,
  showAsRequired,
  price,
  conditionFormItem,
  conditionSelectionIds,
  placesLimit,
  placesUsed,
  retentionPeriodIcon,
}) {
  const currency = useSelector(getCurrency);
  const existingValue = useSelector(state => getFieldValue(state, id));

  return (
    <Field name={conditionFormItem || ''} subscription={{value: true}}>
      {({input: {value: choice}}) => {
        try {
          if (choice) {
            for (const key in conditionSelectionIds) {
              if (conditionSelectionIds[key] > 0) {
                if (JSON.stringify(choice).includes(key)) {
                  return (
                    <FinalCheckbox
                      fieldProps={{
                        className: `${styles.field} ${showAsRequired ? 'required' : ''}`,
                      }}
                      name={htmlName}
                      label={title}
                      disabled={
                        disabled || (placesLimit > 0 && placesUsed >= placesLimit && !existingValue)
                      }
                      required={isRequired}
                    >
                      {retentionPeriodIcon}
                      {!!price && (
                        <Field name={htmlName} subscription={{value: true}}>
                          {({input: {value: checked}}) => (
                            <Label
                              pointing="left"
                              styleName={`price-tag ${!checked ? 'greyed' : ''}`}
                            >
                              {price.toFixed(2)} {currency}
                            </Label>
                          )}
                        </Field>
                      )}
                      {!!placesLimit && (
                        <div style={{marginLeft: '1em', display: 'inline-block'}}>
                          <PlacesLeft
                            placesLimit={placesLimit}
                            placesUsed={placesUsed}
                            isEnabled={!disabled}
                          />
                        </div>
                      )}
                    </FinalCheckbox>
                  );
                }
              }
            }
          }
        } catch (e) {
          // Could not determine field should be shown... Hide field
          return null;
        }
        // Hide field
        return null;
      }}
    </Field>
  );
}

ConditionalCheckboxInput.propTypes = {
  id: PropTypes.number.isRequired,
  htmlName: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  title: PropTypes.string.isRequired,
  isRequired: PropTypes.bool.isRequired,
  showAsRequired: PropTypes.bool.isRequired,
  price: PropTypes.number,
  conditionFormItem: PropTypes.string,
  conditionSelectionIds: PropTypes.object,
  placesLimit: PropTypes.number.isRequired,
  placesUsed: PropTypes.number.isRequired,
  retentionPeriodIcon: PropTypes.element,
};

ConditionalCheckboxInput.defaultProps = {
  disabled: false,
  price: 0,
  conditionFormItem: '',
  conditionSelectionIds: {},
  retentionPeriodIcon: null,
};

export function ConditionalCheckboxSettings() {
  const items = useSelector(getItems);
  const fields = useMemo(() => {
    const res = [];
    for (const itm in items) {
      if (
        items[itm] &&
        items[itm].isEnabled &&
        items[itm].inputType &&
        items[itm].inputType === 'single_choice'
      ) {
        res.push({
          key: `condition-${items[itm].htmlName}`,
          value: items[itm].htmlName,
          text: items[itm].title,
          choices: items[itm].choices,
        });
      }
    }
    return res;
  }, [items]);
  const conditions = useMemo(() => {
    const res = {};
    for (const itm in items) {
      if (
        items[itm] &&
        items[itm].isEnabled &&
        items[itm].inputType &&
        items[itm].inputType === 'single_choice'
      ) {
        res[items[itm].htmlName] = [];
        if (items[itm].choices && Array.isArray(items[itm].choices)) {
          for (const choice of items[itm].choices) {
            if (choice.isEnabled) {
              res[items[itm].htmlName].push({
                key: `${items[itm].htmlName}-${choice.id}`,
                caption: choice.caption,
                extraSlotsPay: false,
                id: choice.id,
                isEnabled: true,
                maxExtraSlots: 0,
                placesLimit: 0,
                price: 0,
              });
            }
          }
        }
      }
    }
    return res;
  }, [items]);
  return (
    <>
      <FinalInput
        name="placesLimit"
        type="number"
        label={Translate.string('Places limit')}
        placeholder={Translate.string('None')}
        step="1"
        min="1"
        validate={v.optional(v.min(0))}
        parse={val => (val === '' ? 0 : +val)}
        format={val => (val === 0 ? '' : val)}
      />
      <FinalDropdown
        name="conditionFormItem"
        label={Translate.string('Condition is based on')}
        options={fields}
        disabled={fields.length === 0}
        parse={p.nullIfEmpty}
        selection
      />
      <Field name="conditionFormItem" subscription={{value: true}} isEqual={_.isEqual}>
        {({input: {value: conditionFormItem}}) => {
          if (
            conditionFormItem &&
            conditions &&
            conditions[conditionFormItem] &&
            Array.isArray(conditions[conditionFormItem])
          ) {
            return (
              <MultiChoiceInput
                htmlName="conditionSelectionIds"
                disabled={false}
                isRequired={false}
                choices={conditions[conditionFormItem]}
                maxChoices={null}
                withExtraSlots={false}
                placesUsed={{}}
              />
            );
          }
          return null;
        }}
      </Field>
    </>
  );
}

// TEMA END: Added a checkbox that is displayed based on a single_choice selection
