import _ from 'lodash';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {FormSpy} from 'react-final-form';
import {useSelector} from 'react-redux';
import {Modal} from 'semantic-ui-react';

import {Translate} from 'indico/react/i18n';
import {Markdown} from 'indico/react/util';

import {getNestedSections} from '../form/selectors';

export default function RegistrationSummary() {
  const sections = useSelector(getNestedSections);
  // const currency = useSelector(getCurrency);
  return (
    <FormSpy subscription={{values: true}}>
      {({values}) => <RegistrationSummaryList values={values} sections={sections} />}
    </FormSpy>
  );
}

function RegistrationSummaryList({values, sections}) {
  const summary = useMemo(() => {
    const result = [];
    // There is no need to store whether the user accepted the privacy policy
    // as it is not possible to submit the form without accepting it.
    let data = _.omit(values, 'agreed_to_privacy_policy');

    // Remove data of hidden fields in conditional sections
    if (sections && Array.isArray(sections) && sections.length > 0) {
      for (const section of sections) {
        if (section.isConditional === true) {
          const filteredItems = section.items.filter(item => item.isEnabled);
          if (filteredItems.length > 1) {
            // check if condition is met
            if (data[filteredItems[0].htmlName] !== true) {
              // purge all non required fields
              filteredItems.shift();
              data = _.omit(data, filteredItems.map(({htmlName}) => htmlName));
            }
          }
        }
      }
    }

    try {
      console.log('--- sections ---');
      console.log(JSON.stringify(sections));

      console.log('--- data ---');
      console.log(JSON.stringify(data));

      // gather all data
      if (sections && Array.isArray(sections) && sections.length > 0) {
        for (const section of sections) {
          const sectionResult = [];
          const filteredItems = section.items.filter(item => item.isEnabled);
          if (filteredItems.length > 0) {
            // iterate over all items
            for (const item of filteredItems) {
              if (
                data[item.htmlName] !== undefined &&
                data[item.htmlName] !== null &&
                item.inputType
              ) {
                switch (item.inputType) {
                  case 'text':
                  case 'textarea':
                  case 'email':
                  case 'po_number':
                  case 'vat_number':
                    sectionResult.push({
                      id: item.id,
                      htmlName: item.htmlName,
                      title: item.title,
                      description: item.description,
                      value: data[item.htmlName],
                    });

                    break;
                  case 'checkbox':
                    sectionResult.push({
                      id: item.id,
                      htmlName: item.htmlName,
                      title: item.title,
                      description: item.description,
                      value:
                        data[item.htmlName] === true
                          ? Translate.string('Yes')
                          : Translate.string('No'),
                    });
                    break;
                  case 'bool':
                    sectionResult.push({
                      id: item.id,
                      htmlName: item.htmlName,
                      title: item.title,
                      description: item.description,
                      value:
                        data[item.htmlName] === true
                          ? Translate.string('Yes')
                          : Translate.string('No'),
                    });
                    break;
                  case 'country':
                    for (const choice of item.choices) {
                      if (choice.countryKey === data[item.htmlName]) {
                        sectionResult.push({
                          id: item.id,
                          htmlName: item.htmlName,
                          title: item.title,
                          description: item.description,
                          choice,
                          value: choice.caption,
                        });
                      }
                    }
                    break;
                  case 'single_choice':
                    if (typeof data[item.htmlName] === 'object') {
                      for (const key of Object.keys(data[item.htmlName])) {
                        for (const choice of item.choices) {
                          if (choice.id === key) {
                            sectionResult.push({
                              id: item.id,
                              htmlName: item.htmlName,
                              title: item.title,
                              description: item.description,
                              choice,
                              value: choice.caption,
                            });
                          }
                        }
                      }
                    }
                    break;
                  default:
                    console.log(`--- item not summarized: ${item.htmlName} ---`);
                    console.log(JSON.stringify(item));
                    console.log(JSON.stringify(data[item.htmlName]));
                    break;
                }
              } else {
                console.log(`--- no data for item ---`);
                console.log(JSON.stringify(item));
              }
            }
            result.push({
              id: section.id,
              title: section.title,
              values: sectionResult,
            });
          } else {
            console.log(`--- filtered items have a length of 0 ---`);
          }
        }
      }
    } catch (e) {
      console.log(e);
    }

    return result;
  }, [sections, values]);

  return (
    <Modal.Content>
      {summary &&
        Array.isArray(summary) &&
        summary.map(section => (
          <div
            className="row border-bottom border-dark pb-2 mb-2"
            key={`summary-section-${section.id}`}
          >
            <div className="col-12 fs-5 fw-bold" key={`summary-section-title-${section.id}`}>
              {section.title}
            </div>
            {section.values &&
              Array.isArray(section.values) &&
              section.values.map(field => (
                <>
                  <div key={`summary-field-title-${field.id}`} className="col-12 col-md-6 mt-2">
                    {field.title}
                  </div>
                  <div
                    key={`summary-field-value-${field.id}`}
                    className="col-12 col-md-6 mt-2 fw-bold"
                  >
                    {field.value}
                  </div>
                  {field.description && field.description !== '' && (
                    <div key={`summary-field-description-${field.id}`} className="col-12">
                      <Markdown targetBlank>{field.description}</Markdown>
                    </div>
                  )}
                </>
              ))}
          </div>
        ))}
    </Modal.Content>
  );
}

RegistrationSummaryList.propTypes = {
  values: PropTypes.any.isRequired,
  sections: PropTypes.any.isRequired,
  // currency: PropTypes.string.isRequired,
};
