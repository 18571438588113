//  TEMA: Added special field for additional participants (accompanying persons with full data and a single-choice input)

import validateEmailURL from 'indico-url:event_registration.check_email';

import _ from 'lodash';
import {nanoid} from 'nanoid';
import PropTypes from 'prop-types';
import React, {useState, useMemo} from 'react';
import {Field, useForm, useFormState} from 'react-final-form';
import {useSelector} from 'react-redux';
import {Button, Form, Label, LabelDetail, Message} from 'semantic-ui-react';

import {
  FinalCheckbox,
  FinalDropdown,
  FinalField,
  FinalInput,
  parsers as p,
  validators as v,
} from 'indico/react/forms';
import {FinalModalForm} from 'indico/react/forms/final-form';
import {useDebouncedAsyncValidate} from 'indico/react/hooks';
import {Param, Translate} from 'indico/react/i18n';
import {indicoAxios, handleAxiosError} from 'indico/utils/axios';
import {camelizeKeys} from 'indico/utils/case';

import {getCurrency, getItems, getStaticData} from '../selectors';

import {Choices, choiceShape} from './ChoicesSetup';
import {PlacesLeft} from './PlacesLeftLabel';
import {SingleChoiceDropdown} from './SingleChoiceInput';

import '../../../styles/regform.module.scss';

// Salutations for BVL
export const titles = [
  // {text: Translate.string('Dr.'), value: 'dr'},
  // {text: Translate.string('Dr.-Ing.'), value: 'dr_ing'},
  // {text: Translate.string('Prof.'), value: 'prof'},
  // {text: Translate.string('Prof. Dr.'), value: 'prof_dr'},
  // {text: Translate.string('Prof. Dr.-Ing.'), value: 'prof_dr_ing'},
  // {text: Translate.string('Ph.D.'), value: 'phd'},
  {text: Translate.string('Dr.'), value: 1},
  {text: Translate.string('Dr.-Ing.'), value: 3},
  {text: Translate.string('Prof.'), value: 2},
  {text: Translate.string('Prof. Dr.'), value: 4},
  {text: Translate.string('Prof. Dr.-Ing.'), value: 5},
  {text: Translate.string('Ph.D.'), value: 6},
];

export const salutations = [
  // {text: Translate.string('Dear Mr.'), value: 'mr'},
  // {text: Translate.string('Dear Ms.'), value: 'ms'},
  // {text: Translate.string('Dear Mx'), value: 'mx'},
  {text: Translate.string('Dear Mr.'), value: 1},
  {text: Translate.string('Dear Ms.'), value: 2},
  {text: Translate.string('Dear Mx'), value: 3},
];

function EmailField({name, participant, otherParticipants, description}) {
  let response, msg;
  const [message, setMessage] = useState({status: '', message: ''});
  const form = useForm();
  const isUpdate = !!participant;

  const {eventId, regformId} = useSelector(getStaticData);
  const validateUrl = useMemo(() => validateEmailURL({event_id: eventId, reg_form_id: regformId}), [
    eventId,
    regformId,
  ]);

  const validateEmail = useDebouncedAsyncValidate(async value => {
    console.log(`Validation invoked for "${value}"`);
    const email = value ? value.trim() : '';

    if (email === '' || email === participant?.email) {
      setMessage({status: '', message: ''});
      return;
    }

    // Prevent adding the same person twice
    if (otherParticipants.some(part => part.email === email)) {
      msg = Translate.string('All participants need to have different email addresses.');
      setMessage({
        status: 'error',
        message: msg,
      });
      return msg;
    }

    // TODO: check against email of primary registrant
    // if (email.length > 9 || email.includes('@') || email.includes('.')) {
    //   setMessage({
    //     status: '',
    //     message: Translate.string('Checking email address...'),
    //   });
    // }

    try {
      response = await indicoAxios.get(validateUrl, {
        params: {email},
      });
    } catch (error) {
      return handleAxiosError(error);
    }

    const data = camelizeKeys(response.data);
    const {status, conflict} = data;

    // A user can have multiple emails associated with their account.
    // Check already added persons if there is any with the same userId
    if (data.user) {
      const existingPerson = otherParticipants.find(part => data.user.id === part.userId);
      if (existingPerson) {
        msg = (
          <Translate>
            This email is associated with{' '}
            <Param name="name" wrapper={<strong />} value={existingPerson.name} /> who is already in
            the list.
          </Translate>
        );
        setMessage({
          status: 'error',
          message: msg,
        });
        return msg;
      }
    }

    if (conflict === 'person-already-exists' || conflict === 'user-and-person-already-exists') {
      form.change('id', data.eventPerson.id);
    }

    if (
      conflict === 'user-already-exists' ||
      conflict === 'person-already-exists' ||
      conflict === 'user-and-person-already-exists'
    ) {
      const obj = data.eventPerson || data.user;
      const objName = obj.fullName || obj.name;
      if (isUpdate) {
        msg = (
          <Translate>
            This email is already used by <Param name="name" wrapper={<strong />} value={objName} />
            . You can update the form with their information.
          </Translate>
        );
      } else {
        msg = (
          <Translate>
            This email is already used by <Param name="name" wrapper={<strong />} value={objName} />
            . You can add the person directly or update the form with their information.
          </Translate>
        );
      }
    } else if (conflict === 'email-invalid') {
      if (data.emailError === 'undeliverable') {
        msg = Translate.string('The domain used in the email address does not exist.');
      } else {
        msg = Translate.string(
          'This email address is invalid or cannot register for the event. Maybe it is already registered.'
        );
      }
    } else if (status === 'ok') {
      msg = '';
    }

    // if (email.length < 10 || !email.includes('@') || !email.includes('.')) {
    //   setMessage({status, message: ''});
    // } else {
    //   setMessage({status, message: ''});
    // }
    setMessage({status, message: ''});
    if (status === 'error') {
      return msg;
    }
  }, 250);

  return (
    <FinalInput
      type="email"
      name={name}
      description={description}
      validate={validateUrl ? validateEmail : undefined}
      // hide the normal error tooltip if we have an error from our async validation
      // hideValidationError={message.status === 'error' ? 'message' : false}
      loaderWhileValidating
    >
      {!!message.message && (
        <Message visible error={message.status === 'error'} warning={message.status === 'warning'}>
          <div>{message.message}</div>
        </Message>
      )}
    </FinalInput>
  );
}

EmailField.propTypes = {
  name: PropTypes.string.isRequired,
  participant: PropTypes.object,
  otherParticipants: PropTypes.array.isRequired,
  description: PropTypes.string,
};

EmailField.defaultProps = {
  participant: null,
  description: '',
};

function AdditionalParticipantModal({
  value,
  header,
  onSubmit,
  onClose,
  disabled,
  isRequired,
  isPurged,
  otherParticipants,
  choicesTicketWithExtraSlots,
  choicesTicket,
  checkboxTicket,
  checkboxTicketDescription,
  choicesCompanyFunction,
  choicesCompanySector,
}) {
  return (
    <FinalModalForm
      id="additionalparticipants-form"
      onSubmit={onSubmit}
      onClose={onClose}
      initialValues={value}
      header={header}
      size="small"
    >
      <Form.Field required width="16">
        <Translate as="label">Ticket Type</Translate>
        <FinalField
          name="ticket"
          component={SingleChoiceDropdown}
          format={val => val || {}}
          required
          isRequired
          // required={isRequired}
          // isRequired={isRequired}
          // validate={val =>
          //   isRequired && (!val || !Object.keys(val).length)
          //     ? Translate.string('Ticket selection is required.')
          //     : undefined
          // }
          validate={val =>
            !val || !Object.keys(val).length
              ? Translate.string('Ticket selection is required.')
              : undefined
          }
          disabled={disabled}
          isPurged={isPurged}
          choices={choicesTicket}
          withExtraSlots={choicesTicketWithExtraSlots}
          // placesUsed={placesUsed}
          existingValue={value ? value.ticket || {} : {}}
          isEqual={_.isEqual}
        />
      </Form.Field>
      <Field name="ticket" subscription={{value: true}}>
        {({input: {value: ticket}}) => {
          if (ticket) {
            try {
              let id = '';
              for (const key in ticket) {
                if (ticket[key] > 0) {
                  id = key;
                }
              }
              console.log(id);
              for (const choice of choicesTicket) {
                console.log(choice);
                if (
                  choice.id === id &&
                  choice.caption.includes('Congress') &&
                  (choice.caption.includes('3') ||
                    choice.caption.includes('Wednesday') ||
                    choice.caption.includes('Mittwoch'))
                ) {
                  return (
                    <FinalCheckbox
                      name="ticketCheckbox"
                      label={checkboxTicket}
                      fluid
                      description={checkboxTicketDescription}
                    />
                  );
                }
              }
            } catch (e) {
              console.log(e);
              // do nothing
              return null;
            }
          }
          return null;
        }}
      </Field>
      <Form.Group widths="equal">
        <Form.Field>
          <Translate as="label">Academic Degree</Translate>
          <FinalDropdown
            name="title"
            fluid
            search
            selection
            options={titles}
            placeholder={Translate.string('None')}
          />
        </Form.Field>
        <Form.Field required>
          <Translate as="label">Salutation</Translate>
          <FinalDropdown name="salutation" fluid search selection options={salutations} required />
        </Form.Field>
      </Form.Group>
      <Form.Group widths="equal">
        <FinalInput name="firstName" label={Translate.string('First Name')} required />
        <FinalInput name="lastName" label={Translate.string('Last Name')} required />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field required>
          <Translate as="label">Business Email Address</Translate>
          <EmailField
            name="email"
            description={Translate.string(
              'The personalized ticket is automatically sent to the participant. If you book for another person, you will automatically receive a copy of the ticket.'
            )}
            participant={value}
            otherParticipants={otherParticipants}
            required
          />
        </Form.Field>
        <FinalInput
          name="phone"
          label={Translate.string('Business Phone Number')}
          description={Translate.string("Please don't share private data.")}
        />
      </Form.Group>
      <FinalInput name="position" label={Translate.string('Position')} required />
      <FinalInput
        name="department"
        label={Translate.string('In which department do you work?')}
        required
      />
      {/* <Form.Field required>
        <Translate as="label">Company Name</Translate>
        <FinalAffiliationField hasPredefinedAffiliations={false} required />
      </Form.Field> */}
      <Form.Group widths="equal">
        <Form.Field required width="16">
          <Translate as="label">What is your position within the company?</Translate>
          <FinalField
            name="companyFunction"
            component={SingleChoiceDropdown}
            format={val => val || {}}
            required
            isRequired
            validate={val =>
              !val || !Object.keys(val).length
                ? Translate.string(
                    'Please choose the position of the participant within the company.'
                  )
                : undefined
            }
            disabled={disabled}
            isPurged={isPurged}
            choices={choicesCompanyFunction}
            withExtraSlots={false}
            // placesUsed={placesUsed}
            existingValue={value ? value.companyFunction || {} : {}}
            isEqual={_.isEqual}
          />
        </Form.Field>
        <Form.Field required width="16">
          <Translate as="label">What kind of industry sector do you work in?</Translate>
          <FinalField
            name="companySector"
            component={SingleChoiceDropdown}
            format={val => val || {}}
            required
            isRequired
            validate={val =>
              !val || !Object.keys(val).length
                ? Translate.string('Please choose the industry sector the participant works in.')
                : undefined
            }
            disabled={disabled}
            isPurged={isPurged}
            choices={choicesCompanySector}
            withExtraSlots={false}
            // placesUsed={placesUsed}
            existingValue={value ? value.companySector || {} : {}}
            isEqual={_.isEqual}
          />
        </Form.Field>
      </Form.Group>
    </FinalModalForm>
  );
}

AdditionalParticipantModal.propTypes = {
  value: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    salutation: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string,
    companyFunction: PropTypes.object.isRequired,
    companySector: PropTypes.object.isRequired,
    ticket: PropTypes.object.isRequired,
  }),
  header: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  isRequired: PropTypes.bool.isRequired,
  isPurged: PropTypes.bool.isRequired,
  otherParticipants: PropTypes.arrayOf(PropTypes.object).isRequired,
  choicesTicketWithExtraSlots: PropTypes.bool.isRequired,
  choicesTicket: PropTypes.arrayOf(PropTypes.shape(choiceShape)).isRequired,
  checkboxTicket: PropTypes.string.isRequired,
  checkboxTicketDescription: PropTypes.string.isRequired,
  choicesCompanyFunction: PropTypes.arrayOf(PropTypes.shape(choiceShape)).isRequired,
  choicesCompanySector: PropTypes.arrayOf(PropTypes.shape(choiceShape)).isRequired,
};

AdditionalParticipantModal.defaultProps = {
  value: {
    id: null,
    title: null,
    salutation: null,
    firstName: null,
    lastName: null,
    email: null,
    phone: null,
    companyFunction: {},
    companySector: {},
    ticket: {},
  },
};

// Gather all additional participants field's counts.
function countAllAdditionalParticipants(items, formState) {
  const allAdditionalParticipantFieldNames = Object.values(items)
    .filter(
      f =>
        (f.inputType === 'accompanying_persons' || f.inputType === 'additional_participants') &&
        f.personsCountAgainstLimit
    )
    .map(apf => apf.htmlName);
  return allAdditionalParticipantFieldNames.reduce(
    (count, field) => count + formState.values[field]?.length || 0,
    0
  );
}

function calculatePlaces(availablePlaces, maxPersons, personsInCurrentField, items, formState) {
  if (availablePlaces === null) {
    // Field does not count towards registration limit...
    if (!maxPersons) {
      // ...and has no person limit.
      return [null, Infinity];
    } else {
      // ...and has a person limit.
      return [personsInCurrentField, maxPersons];
    }
  } else {
    // Field counts towards registration limit...
    const personsInAllFieldsCount = countAllAdditionalParticipants(items, formState);

    if (!maxPersons || maxPersons >= availablePlaces - personsInAllFieldsCount) {
      // ...and has no person limit, or its person limit is greater than the registration limit.
      return [personsInAllFieldsCount, availablePlaces];
    } else {
      // ...and has a person limit lower than the registration limit.
      return [personsInCurrentField, maxPersons];
    }
  }
}

function AdditionalParticipantsComponent({
  // existingValue,
  value,
  onChange,
  disabled,
  isRequired,
  isPurged,
  // price,
  availablePlaces,
  maxPersons,
  choicesTicketWithExtraSlots,
  choicesTicket,
  checkboxTicket,
  checkboxTicketDescription,
  choicesCompanyFunction,
  choicesCompanySector,
}) {
  const [operation, setOperation] = useState({type: null, otherParticipants: null});
  const currency = useSelector(getCurrency);
  const items = useSelector(getItems);
  const formState = useFormState();
  const [placesUsed, placesLimit] = calculatePlaces(
    availablePlaces,
    maxPersons,
    value.length,
    items,
    formState
  );
  const changeReducer = action => {
    switch (action.type) {
      case 'ADD':
        return [...value, {id: `new:${nanoid()}`, ...action.participant}];
      case 'EDIT':
        return value.map(participant =>
          participant.id === action.participant.id ? action.participant : participant
        );
      case 'REMOVE':
        return value.filter(participant => participant.id !== action.id);
    }
  };
  const handleAdditionalParticipantAdd = () => {
    setOperation({type: 'ADD', participant: null});
  };
  const handleAdditionalParticipantEdit = id => {
    setOperation({type: 'EDIT', participant: value.find(participant => participant.id === id)});
  };
  const handleAdditionalParticipantRemove = id => {
    onChange(changeReducer({type: 'REMOVE', id}));
  };
  const handleModalClose = () => {
    setOperation({type: null, participant: null});
  };
  return (
    <Form.Group styleName="additionalparticipants-field">
      <ul>
        {!value.length && (
          <li styleName="light">
            <Translate>No additional participants</Translate>
          </li>
        )}
        {value.map(participant => (
          <li key={participant.id}>
            <span className="d-flex flex-column flex-md-row w-100">
              <div style={{minWidth: '50%'}}>
                {participant.firstName} {participant.lastName} ({participant.email})
              </div>
              {participant.ticket &&
                participant.ticket !== {} &&
                Object.keys(participant.ticket).map(key => {
                  for (const choice of choicesTicket) {
                    console.log(choice);
                    if (choice.id === key && choice.price > 0) {
                      return (
                        <Label pointing="left">
                          {(choice.price * participant.ticket[key]).toFixed(2)} {currency}
                          <LabelDetail>{choice.caption}</LabelDetail>
                        </Label>
                      );
                    }
                  }
                  return null;
                })}
            </span>
            <div styleName="actions">
              <a
                className="icon-edit"
                title={Translate.string('Edit this participant')}
                onClick={() => handleAdditionalParticipantEdit(participant.id)}
              />
              <a
                className="icon-remove"
                title={Translate.string('Remove this participant')}
                onClick={() => handleAdditionalParticipantRemove(participant.id)}
              />
            </div>
          </li>
        ))}
      </ul>
      <div styleName="summary">
        <Button
          size="small"
          type="button"
          onClick={handleAdditionalParticipantAdd}
          disabled={disabled || placesLimit - placesUsed === 0}
        >
          <Translate>Add additional participant</Translate>
        </Button>
        {placesLimit !== Infinity && placesLimit <= placesUsed && (
          <div styleName="places-left">
            <PlacesLeft placesLimit={placesLimit} placesUsed={placesUsed} isEnabled={!disabled} />
          </div>
        )}
      </div>
      {['ADD', 'EDIT'].includes(operation.type) && (
        <AdditionalParticipantModal
          value={operation.participant}
          header={
            operation.type === 'EDIT'
              ? Translate.string('Edit additional participant')
              : Translate.string('Add additional participant')
          }
          onSubmit={formData => {
            onChange(changeReducer({type: operation.type, participant: formData}));
            handleModalClose();
          }}
          onClose={handleModalClose}
          disabled={disabled}
          isRequired={isRequired}
          isPurged={isPurged}
          otherParticipants={value}
          choicesTicketWithExtraSlots={choicesTicketWithExtraSlots}
          choicesTicket={choicesTicket}
          checkboxTicket={checkboxTicket}
          checkboxTicketDescription={checkboxTicketDescription}
          choicesCompanyFunction={choicesCompanyFunction}
          choicesCompanySector={choicesCompanySector}
        />
      )}
    </Form.Group>
  );
}

AdditionalParticipantsComponent.propTypes = {
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  isRequired: PropTypes.bool.isRequired,
  isPurged: PropTypes.bool.isRequired,
  // price: PropTypes.number,
  availablePlaces: PropTypes.number,
  maxPersons: PropTypes.number,
  choicesTicketWithExtraSlots: PropTypes.bool.isRequired,
  choicesTicket: PropTypes.arrayOf(PropTypes.shape(choiceShape)).isRequired,
  checkboxTicket: PropTypes.string.isRequired,
  checkboxTicketDescription: PropTypes.string.isRequired,
  choicesCompanyFunction: PropTypes.arrayOf(PropTypes.shape(choiceShape)).isRequired,
  choicesCompanySector: PropTypes.arrayOf(PropTypes.shape(choiceShape)).isRequired,
};

AdditionalParticipantsComponent.defaultProps = {
  // price: 0,
  availablePlaces: null,
  maxPersons: null,
};

export default function AdditionalParticipantsInput({
  htmlName,
  disabled,
  isRequired,
  isPurged,
  // price,
  availablePlaces,
  maxPersons,
  choicesTicketWithExtraSlots,
  choicesTicket,
  checkboxTicket,
  checkboxTicketDescription,
  choicesCompanyFunction,
  choicesCompanySector,
}) {
  return (
    <FinalField
      name={htmlName}
      component={AdditionalParticipantsComponent}
      disabled={disabled}
      isRequired={isRequired}
      isPurged={isPurged}
      // price={price}
      availablePlaces={availablePlaces}
      maxPersons={maxPersons}
      choicesTicketWithExtraSlots={choicesTicketWithExtraSlots}
      choicesTicket={choicesTicket}
      checkboxTicket={checkboxTicket}
      checkboxTicketDescription={checkboxTicketDescription}
      choicesCompanyFunction={choicesCompanyFunction}
      choicesCompanySector={choicesCompanySector}
    />
  );
}

AdditionalParticipantsInput.propTypes = {
  // id: PropTypes.number.isRequired,
  htmlName: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  isPurged: PropTypes.bool.isRequired,
  // price: PropTypes.number,
  availablePlaces: PropTypes.number,
  maxPersons: PropTypes.number,
  choicesTicketWithExtraSlots: PropTypes.bool,
  choicesTicket: PropTypes.arrayOf(PropTypes.shape(choiceShape)).isRequired,
  checkboxTicket: PropTypes.string,
  checkboxTicketDescription: PropTypes.string,
  choicesCompanyFunction: PropTypes.arrayOf(PropTypes.shape(choiceShape)).isRequired,
  choicesCompanySector: PropTypes.arrayOf(PropTypes.shape(choiceShape)).isRequired,
};

AdditionalParticipantsInput.defaultProps = {
  disabled: false,
  isRequired: false,
  // price: 0,
  availablePlaces: null,
  maxPersons: null,
  choicesTicketWithExtraSlots: false,
  checkboxTicket: '',
  checkboxTicketDescription: '',
};

export const additionalParticipantsSettingsInitialData = {
  maxPersons: 1,
  personsCountAgainstLimit: false,
  choicesTicketDefaultItem: null,
  choicesTicketWithExtraSlots: false,
  choicesTicket: [],
  checkboxTicket: '',
  checkboxTicketDescription: '',
  choicesCompanyFunction: [],
  choicesCompanySector: [],
};

export function AdditionalParticipantsSettings() {
  return (
    <>
      <FinalInput
        name="maxPersons"
        type="number"
        label={Translate.string('Maximum per registration')}
        placeholder={Translate.string('No maximum')}
        step="1"
        min="0"
        validate={v.optional(v.min(0))}
        fluid
        format={val => val || ''}
        parse={val => +val || 0}
      />
      <FinalCheckbox
        name="personsCountAgainstLimit"
        label={Translate.string('Additional participants count against registration limit')}
      />
      <Field name="choicesTicket" subscription={{value: true}} isEqual={_.isEqual}>
        {({input: {value: choicesTicket}}) => (
          <FinalDropdown
            name="choicesTicketDefaultItem"
            label={Translate.string('Default Ticket Option')}
            options={choicesTicket
              .filter(c => c.isEnabled)
              .map(c => ({key: c.id, value: c.id, text: c.caption}))}
            disabled={!choicesTicket.some(c => c.isEnabled)}
            parse={p.nullIfEmpty}
            selection
          />
        )}
      </Field>
      <FinalCheckbox
        name="choicesTicketWithExtraSlots"
        label={Translate.string('Enable extra slots for Ticket')}
      />
      <Field name="choicesTicketWithExtraSlots" subscription={{value: true}}>
        {({input: {value: choicesTicketWithExtraSlots}}) => (
          <FinalField
            name="choicesTicket"
            label={Translate.string('Ticket Choices')}
            component={Choices}
            withExtraSlots={choicesTicketWithExtraSlots}
            isEqual={_.isEqual}
            required
          />
        )}
      </Field>
      <FinalInput
        name="checkboxTicket"
        label={Translate.string('Label for Checkbox below ticket Choice')}
        required
        fluid
      />
      <FinalInput
        name="checkboxTicketDescription"
        label={Translate.string('Description for Checkbox below ticket Choice')}
        fluid
      />
      <FinalField
        name="choicesCompanyFunction"
        label={Translate.string('What function does the participant have within the company? ')}
        component={Choices}
        withExtraSlots={false}
        isEqual={_.isEqual}
        required
      />
      <FinalField
        name="choicesCompanySector"
        label={Translate.string('What kind of industry sector does the participant work in?')}
        component={Choices}
        withExtraSlots={false}
        isEqual={_.isEqual}
        required
      />
    </>
  );
}
//  TEMA: Added special field for additional participants (accompanying persons with full data and a single-choice input)
